<template>
  <div class="container home3">
    <div class="row">
      <maintop1 />
      <maintop2 />
      <div
        :class="
          flag_nav ? 'main_fix_offset col-xl-10' : 'main_fix_width col-xl-12'
        "
      >
        <div
          class="MainLayout_bodyContainer__P0jkj"
          style="max-width: 1200px; padding-top: 0px"
        >
          <div class="DesignPage_root__pLrWe">
            <div
              class="adm-space adm-space-block adm-space-vertical"
              style="width: 100%"
            >
              <div class="mt-2">
                <div class="GbLeftIemsRightImg_block__9V890">
                  <div class="vip_md_div">
                    <div>
                      <span class="vip_title"
                        >{{ $t("vip_introduce_text1") }} </span
                      ><span class="vip_subtitle"
                        ><br />{{ $t("vip_introduce_text2") }}
                      </span>
                    </div>
                    <img
                      class="GbLeftIemsRightImg_image__h7xi4 d-block d-md-none"
                      src="@/assets/img/invite/3057579A8D69CDA55727B63237FA1893.png"
                    />
                    <ul class="GbTextList_ul__BBcOu">
                      <li class="GbTextList_li__QRXdQ">
                        <img
                          class="GbTextList_icon__Ht2xj"
                          src="@/assets/img/invite/1CD3C1640F601A84C856DC3D3062D96D.png"
                        />
                        <div class="GbTextList_text_area__wfThC">
                          <span class="GbTextList_title__68g6n"
                            >{{ $t("vip_introduce_text3") }} &amp;
                            {{ $t("vip_introduce_text4") }}</span
                          ><span class="GbTextList_sub_title__w6KsB">{{
                            $t("vip_introduce_text5")
                          }}</span>
                        </div>
                      </li>
                      <li class="GbTextList_li__QRXdQ">
                        <img
                          class="GbTextList_icon__Ht2xj"
                          src="@/assets/img/invite/833230B2993D150263AC11F9FD6C7C97.png"
                        />
                        <div class="GbTextList_text_area__wfThC">
                          <span class="GbTextList_title__68g6n">{{
                            $t("vip_introduce_text6")
                          }}</span
                          ><span class="GbTextList_sub_title__w6KsB">{{
                            $t("vip_introduce_text7")
                          }}</span>
                        </div>
                      </li>
                      <li class="GbTextList_li__QRXdQ">
                        <img
                          class="GbTextList_icon__Ht2xj"
                          src="@/assets/img/invite/26452E0D3010D28E3FF655400C974145.png"
                        />
                        <div class="GbTextList_text_area__wfThC">
                          <span class="GbTextList_title__68g6n">{{
                            $t("vip_introduce_text8")
                          }}</span
                          ><span class="GbTextList_sub_title__w6KsB">{{
                            $t("vip_introduce_text9")
                          }}</span>
                        </div>
                      </li>
                      <li class="GbTextList_li__QRXdQ">
                        <img
                          class="GbTextList_icon__Ht2xj"
                          src="@/assets/img/invite/21D7F86DE91C1A503C8161E6E5F0B7BA.png"
                        />
                        <div class="GbTextList_text_area__wfThC">
                          <span class="GbTextList_title__68g6n">{{
                            $t("vip_introduce_text10")
                          }}</span
                          ><span class="GbTextList_sub_title__w6KsB">{{
                            $t("vip_introduce_text11")
                          }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div
                    class="GbLeftIemsRightImg_areaTwo__HhsbL d-none d-md-block"
                    style="padding-left: 10px"
                  >
                    <img
                      class="GbLeftIemsRightImg_image__h7xi4"
                      src="@/assets/img/invite/3057579A8D69CDA55727B63237FA1893.png"
                    />
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <div class="GbThreeIntro_block__aoQa6 d-md-flex">
                  <div class="GbThreeIntro_arrowOne__rWqP2">
                    <span
                      role="img"
                      aria-label="down"
                      class="anticon anticon-down GbThreeIntro_arrow__KdSA- d-block d-md-none"
                      ><svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="down"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"
                        ></path></svg
                    ></span>
                    <span
                      role="img"
                      aria-label="right"
                      class="anticon anticon-right GbThreeIntro_arrow__KdSA- d-none d-md-block"
                      ><svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="right"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                        ></path></svg
                    ></span>
                  </div>
                  <div class="GbThreeIntro_arrowTwo__yd1yV">
                    <span
                      role="img"
                      aria-label="down"
                      class="anticon anticon-down GbThreeIntro_arrow__KdSA- d-block d-md-none"
                      ><svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="down"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"
                        ></path></svg
                    ></span>
                    <span
                      role="img"
                      aria-label="right"
                      class="anticon anticon-right GbThreeIntro_arrow__KdSA- d-none d-md-block"
                      ><svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="right"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                        ></path></svg
                    ></span>
                  </div>
                  <div class="GbThreeIntro_area__bB13F">
                    <div>
                      <img
                        src="@/assets/img/invite/B463CAA46E5F41E790A6FCE476AED149.png"
                        class="GbThreeIntro_img__YoNog"
                      /><img
                        src="@/assets/img/invite/one.svg"
                        class="GbThreeIntro_img__YoNog"
                      />
                    </div>
                    <div class="GbThreeIntro_title__jtzWH">
                      <span>{{ $t("vip_introduce_text12") }}</span>
                    </div>
                    <div class="GbThreeIntro_content__srBLj">
                      <span>{{ $t("vip_introduce_text13") }}</span>
                    </div>
                  </div>
                  <div class="GbThreeIntro_area__bB13F">
                    <div>
                      <img
                        src="@/assets/img/invite/556C83C5A2DD1C30A379FA7A5925E426.png"
                        class="GbThreeIntro_img__YoNog"
                      /><img
                        src="@/assets/img/invite/two.svg"
                        class="GbThreeIntro_img__YoNog"
                      />
                    </div>
                    <div class="GbThreeIntro_title__jtzWH">
                      <span>{{ $t("vip_introduce_text14") }}</span>
                    </div>
                    <div class="GbThreeIntro_content__srBLj">
                      <span>{{ $t("vip_introduce_text15") }}</span>
                    </div>
                  </div>
                  <div class="GbThreeIntro_area__bB13F">
                    <div>
                      <img
                        src="@/assets/img/invite/8C08EA1FAABFED598AA8860383AE86FA.png"
                        class="GbThreeIntro_img__YoNog"
                      /><img
                        src="@/assets/img/invite/three.svg"
                        class="GbThreeIntro_img__YoNog"
                      />
                    </div>
                    <div class="GbThreeIntro_title__jtzWH">
                      <span>{{ $t("vip_introduce_text16") }}</span>
                    </div>
                    <div class="GbThreeIntro_content__srBLj">
                      <span>{{ $t("vip_introduce_text17") }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <div>
                  <div
                    class="adm-grid"
                    style="--columns: 2; --gap: 8px; --gap2: 33px"
                  >
                    <div class="adm-grid-item" v-for="(l, k) in list" :key="k">
                      <div class="GbVipLevelList_card__WPlhi">
                        <div
                          class="C000044_lazyLoadImage__Tc8-e GbVipLevelList_levelImage__rOH-j"
                        >
                          <span
                            class="C000044_placeholder__gYrus lazy-load-image-background black-and-white lazy-load-image-loaded"
                            style="
                              color: transparent;
                              display: inline-block;
                              height: 100%;
                              width: 100%;
                            "
                            ><img
                              style="height: 100%; width: 100%"
                              src="@/assets/img/invite/3CA2721CD70A429A28DEF09573B20C85.png"
                          /></span>
                        </div>
                        <div class="GbVipLevelList_levelName__CRSLH">
                          {{$t("home_text3")}}{{ l.uname }}
                        </div>
                        <div class="GbVipLevelList_levelDesc__yFSMH">
                          <div class="GbVipLevelList_label__dsnpy">
                            {{ $t("deposit") }}
                          </div>
                          <div class="GbVipLevelList_value__yR41I">
                            $ {{ l.bonus }}
                          </div>
                        </div>
                        <div class="GbVipLevelList_levelDesc__yFSMH">
                          <div class="GbVipLevelList_label__dsnpy">
                            {{ $t("vip_introduce_text18") }}
                          </div>
                          <div class="GbVipLevelList_value__yR41I">
                            {{ l.bet }}
                          </div>
                        </div>
                        <div class="GbVipLevelList_levelDesc__yFSMH">
                          <div class="GbVipLevelList_label__dsnpy">
                            {{ $t("com_cash_back") }}
                          </div>
                          <div class="GbVipLevelList_value__yR41I">
                            {{ l.cashback }}%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>
<script>
import "@/assets/style/vip.css";
import maintop1 from "./Main_top1.vue";
import maintop2 from "./Main_top2.vue";
import Footer from "./Home/Footer.vue";
import { mapGetters } from "vuex";

export default {
  name: "vip",
  setup() {},
  data() {
    return {
      list: [],
    };
  },
  computed: {
    ...mapGetters(["flag_nav"]),
  },
  components: {
    Footer,
    maintop1,
    maintop2,
  },
  watch: {},
  created() {this.vip_list()},
  mounted() {},
  methods: {
    vip_list() {
      let param = {
        action: "vip_list",
        body: {
          uid: localStorage.getItem("uid"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.list = res.data;
      });
    },
  },
};
</script>
